// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
 /*firebaseConfig: {
    apiKey: "AIzaSyCaLoFUA5ZW81XZcCaPvDk62Mgzr1qCh5c",
    authDomain: "el-sotano-19237.firebaseapp.com",
    projectId: "el-sotano-19237",
    storageBucket: "el-sotano-19237.appspot.com",
    messagingSenderId: "837740956317",
    appId: "1:837740956317:web:f89977ea9f6c00c34a6274",
    measurementId: "G-R4716T45CV"
  }*/


  firebaseConfig: {
    apiKey: "AIzaSyAD1C3YmmS02-vcZcPlBHmkwhanXZXYfBY",
    authDomain: "el-sotano-b18a4.firebaseapp.com",
    projectId: "el-sotano-b18a4",
    storageBucket: "el-sotano-b18a4.firebasestorage.app",
    messagingSenderId: "148911231161",
    appId: "1:148911231161:web:9c39f9e8df99e76328cb68",
    measurementId: "G-GFQC2T5P45"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
